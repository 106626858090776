import React from 'react';
import './App.css';
import { RedocStandalone } from 'redoc';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import internalSpec from './spec/internal.yaml';
import externalSpec_V1 from './spec/external-v1.yml';
import systemSpec from './spec/system.yaml';

const App = () => (
  <div className="App">
    <header className="App-header"></header>
    <Router>
      <div>
        <Route exact path="/">
          <Redirect to={{ pathname: '/v1' }} />
        </Route>
        <Route exact path="/docs/build">
          <Redirect to={{ pathname: '/v1' }} />
        </Route>
        <Route exact path="/internal" component={InternalApiDocs} />
        <Route exact path="/v1" component={ExternalApiDocs_V1} />
        <Route exact path="/v0.1" component={ExternalApiDocs_V0_1} />
        <Route exact path="/system" component={SystemApiDocs} />
      </div>
    </Router>
  </div>
);

const InternalApiDocs = () => (
  <RedocStandalone
    specUrl={internalSpec}
    options={{
      hideDownloadButton: false,
      downloadFileName: 'openapi_internal.yaml',
      menuToggle: true,
    }}
  />
);

const ExternalApiDocs_V0_1 = () => (
  <div>
    <h2>
      FMS API v0.1 has been discontinued. Please use the <a href="https://docs.fms.web.auto">latest version</a>.
    </h2>
    <h3>
      For more information, please see{' '}
      <a href="https://docs.web.auto/en/developers-guides/fms-api/release-notes/20221220">
        '[Important] FMS API v0.1 is scheduled to be discontinued'
      </a>
      .
    </h3>
  </div>
);

const ExternalApiDocs_V1 = () => (
  <RedocStandalone
    specUrl={externalSpec_V1}
    options={{
      hideDownloadButton: false,
      downloadFileName: 'openapi_v1.yaml',
      menuToggle: true,
    }}
  />
);

const SystemApiDocs = () => (
  <RedocStandalone
    specUrl={systemSpec}
    options={{
      hideDownloadButton: false,
      downloadFileName: 'openapi_system.yaml',
      menuToggle: true,
    }}
  />
);

export default App;
